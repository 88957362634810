<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-6 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <div
              class="btn-group"
            >
              <b-dropdown
                variant="button"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <a
                    v-if="hasAdminPermission('view Memoir highlights')"
                    class="btn btn-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Download memoirs highlights"
                    :disabled="exportLoading"
                  >
                    <a
                      class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                      :class="{'d-none': !exportLoading}"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Loading export..."
                    ><i
                      data-feather="loader"
                    /></a>
                    <span
                      class="loop-loading"
                      :class="{'d-none': exportLoading}"
                    >
                      <i
                        data-feather="file"
                        class="me-25"
                      />
                    </span>

                    {{ !exportLoading ? 'Download memoirs highlights' : 'Downloading memoirs highlights...' }}
                  </a>
                </template>
                <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                  <b-dropdown-item
                    v-for="(auxyear, index) in memoirYears"
                    :key="index"
                    @click="downloadZip(auxyear)"
                  >
                    <a
                      data-bs-toggle="offcanvas"
                      :data-bs-target="`#exportFromYear${auxyear}`"
                      :aria-controls="`#exportFromYear${auxyear}`"
                    >{{ auxyear }}</a>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
            <!-- <a
              v-if="hasAdminPermission('view Memoir highlights')"
              class="btn btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Download memoirs highlights"
              :disabled="exportLoading"
              @click="downloadZip()"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                :class="{'d-none': !exportLoading}"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Loading export..."
              ><i
                data-feather="loader"
              /></a>
              <span
                class="loop-loading"
                :class="{'d-none': exportLoading}"
              >
                <i
                  data-feather="file"
                  class="me-25"
                />
              </span>

              {{ !exportLoading ? 'Download memoirs highlights' : 'Downloading memoirs highlights...' }}
            </a> -->
            <a
              v-if="hasAdminPermission('view Memoir highlights')"
              class="btn btn-primary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Download PDF memoirs highlights"
              :disabled="exportLoadingPDF"
              @click="downloadPDF()"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                :class="{'d-none': !exportLoadingPDF}"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Loading export..."
              ><i
                data-feather="loader"
              /></a>
              <span
                class="loop-loading"
                :class="{'d-none': exportLoadingPDF}"
              >
                <i
                  data-feather="file"
                  class="me-25"
                />
              </span>

              {{ !exportLoadingPDF ? 'Download PDF memoirs highlights' : 'Downloading PDF memoirs highlights...' }}
            </a>
            <a
              v-if="hasAdminPermission('view Memoir highlights') || (activeMemoir.data && activeMemoir.data.highlight)"
              class="btn btn-icon btn-primary mx-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'researcher.memoir-highlight.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'memoir highlights'"
              :total="itemsTotal"
              :fetch-path="'memoirHighlights/fetch'"
              :filter-path="'memoirHighlights/filter'"
              :actions="hasAdminPermission('view Memoir highlights')"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'researcher.memoir-highlight.view', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i data-feather="eye" />
                        View memoir highlight
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('update Memoir highlights')"
                        :href="$router.resolve({ name: 'researcher.memoir-highlight.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                        Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete Memoir highlights')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view Memoir highlights')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                </div>
                <hr>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Title</label>
                  <input
                    v-model="filters.title"
                    type="text"
                    class="form-control"
                    placeholder=""
                    @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: `${$event.target.value}` }, 'title')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Area</label>
                  <v-select
                    v-if="areas.length !== undefined"
                    v-model="filters.areas"
                    multiple
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Areas: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'areas')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All areas
                    </option>
                  </v-select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Year</label>
                  <date-picker
                    v-model="filters['year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Year: ${$event}`, value: `${$event}` }, 'year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Status</label>
                  <v-select
                    v-model="filters.status"
                    label="status"
                    :options="statuses"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.name : ''}`, value: $event }, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Selected for publishing</label>
                  <select
                    id=""
                    v-model="filters.selected_for_publish"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Selected for publishing: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'selected_for_publish')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected registers
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li
                      v-for="(status, index) in statuses"
                      :key="index"
                      class="list-group-item draggable"
                    >
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              :id="`tableAction${index}`"
                              v-model="actions.changeStatus"
                              type="radio"
                              :value="status.id"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              :for="`tableAction${index}`"
                            >Check status to {{ status.status }}</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="dateAction"
                              v-model="actions.addSpecialAccess.status"
                              type="checkbox"
                              class="form-check-input"
                              @change="!$event.target.checked ? actions.addSpecialAccess.value = null : ''"
                            >
                            <label
                              class="form-check-label"
                              for="dateAction"
                            >Add special access</label>
                          </div>
                          <!-- <date-picker
                              v-if="actions.addSpecialAccess.status"
                              v-model="actions.addSpecialAccess.value"
                              format="D MMM Y"
                              value-type="format"
                              class="w-100"
                            /> -->
                        </div>
                      </div>
                    </li>
                    <div
                      v-if="actions.addSpecialAccess.status"
                      class="aux-clandar"
                    >
                      <date-picker
                        v-model="actions.addSpecialAccess.value"
                        format="D MMM Y"
                        value-type="format"
                        class="w-100"
                      />
                    </div>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    v-if="!sending"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                  <button
                    v-if="sending"
                    class="btn btn-dark mb-1 w-100"
                    type="button"
                    disabled=""
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span class="ms-25 align-middle">Applying...</span>
                  </button>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import CompleteTable from '../../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },
  data() {
    return {
      sending: false,
      selectedItems: [],
      actions: {
        changeStatus: null,
        addSpecialAccess: {
          status: false,
          value: null,
        },
      },
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'Year',
          checked: true,
          order: 2,
        },
        {
          name: 'Title',
          checked: true,
          order: 3,
        },
        {
          name: 'Coauthors',
          checked: true,
          order: 4,
        },
        {
          name: 'Status',
          checked: true,
          order: 5,
        },
        {
          name: 'Selected for publishing',
          checked: true,
          order: 6,
        },
        {
          name: 'Special access',
          checked: true,
          order: 7,
        },
        {
          name: 'Host 1',
          checked: false,
          only_admin: true,
          order: 8,
        },
        {
          name: 'Host 2',
          checked: false,
          only_admin: true,
          order: 9,
        },
        {
          name: 'Host 2',
          checked: false,
          only_admin: true,
          order: 10,
        },
      ],
      sortMapping: {
        Researcher: 'profile_name',
        Year: 'profile.year',
        Title: 'title',
        Status: 'status.status_table',
        'Selected for publishing': 'select_for_publish',
        'Special access': 'special_access',
        'Host 1': 'profile_host_1',
        'Host 2': 'profile_host_2',
        Coauthors: 'coAuthors_table',
      },
      title: 'Memoir highlights',
      exportLoading: false,
      exportLoadingPDF: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      items: 'memoirHighlights/items',
      itemsTotal: 'memoirHighlights/itemsTotal',
      loggedUser: 'auth/admin',
      statuses: 'status/statuses',
      users: 'users/fakeUsers',
      areas: 'areas/areas',
      activeMemoir: 'memoirs/activeMemoir',
      memoirYears: 'memoirs/years',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'memoir_highlights')
    await this.$store.dispatch('memoirHighlights/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'memoir_highlights',
      })
    }
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    this.$store.dispatch('status/filterStatus', 'Memoirs profiles')
    this.$store.dispatch('memoirs/getYears')
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async downloadZip(year) {
      this.exportLoading = true
      await this.$store.dispatch('memoirHighlights/downloadZipHighlights', {
        year,
      })
      Vue.swal('Export started', 'You will receive an email when its done', 'success')
      this.exportLoading = false
    },
    async downloadPDF() {
      if (this.selectedItems.length > 0) {
        this.exportLoadingPDF = true
        await this.$store.dispatch('memoirHighlights/downloadZipHighlightsPDF', {
          year: 2023, // Hardcoded
          itemIds: this.selectedItems.map(el => el.id),
        })
        this.exportLoadingPDF = false
      } else {
        Vue.swal('No items selected', '', 'warning')
      }
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('memoirHighlights/applyActions', { actions: this.actions, itemIds }).then(() => {
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
              this.$toastr.success('', 'Actions applied successfully!')
              this.$store.dispatch('memoirHighlights/filter', this.filters)
              this.actions = {
                changeStatus: null,
                addSpecialAccess: {
                  status: false,
                  value: null,
                },
              }
            })
          }
        })
      } else {
        Vue.swal('No items selected', '', 'warning')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.changeStatus) {
        str += '<p class="text-success">Change status</p><br>'
      }

      if (this.actions.addSpecialAccess.status) {
        str += '<p class="text-success">Add special access</p>'
      }

      return str
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'memoirHighlights', url: `${Vue.prototype.$groupUrl}/memoir-highlights/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
  },
}
</script>
